<template>
    <main>
        <Navbar />
        <div class="main">
           <div class="container">
                <template v-if="pipe == 3">
                    <div class="w-100" role="group" :class="platform == 'mobile' ? 'btn-group-vertical' : 'btn-group'">
                        <button type="button" class="btn btn-secondary" @click="user.type = 1" :class="{'active': user.type == 1}" :disabled="user.type == 1">
                            <template v-if="platform == 'mobile'">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <span>Vendedor</span>
                                    </div>
                                    <div>
                                        <i class="fas fa-user-tag"></i>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <i class="fas fa-user-tag mr-2"></i>
                                <span>Vendedor</span>
                            </template>
                        </button>
                        <button type="button" class="btn btn-secondary" @click="user.type = 2" :class="{'active': user.type == 2}" :disabled="user.type == 2">
                            <template v-if="platform == 'mobile'">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <span>Gerente</span>
                                    </div>
                                    <div>
                                        <i class="fas fa-user-friends"></i>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <i class="fas fa-user-friends mr-2"></i>
                                <span>Gerente</span>
                            </template>
                        </button>
                        <button type="button" class="btn btn-secondary" @click="user.type = 3" :class="{'active': user.type == 3}" :disabled="user.type == 3">
                            <template v-if="platform == 'mobile'">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <span>Admin</span>
                                    </div>
                                    <div>
                                        <i class="fas fa-user-shield"></i>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <i class="fas fa-user-shield mr-2"></i>
                                <span>Admin</span>
                            </template>
                        </button>
                        <button type="button" class="btn btn-secondary" @click="user.type = 5" :class="{'active': user.type == 5}" :disabled="user.type == 5">
                            <template v-if="platform == 'mobile'">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <span>Supervisor</span>
                                    </div>
                                    <div>
                                        <i class="fas fa-users-crown"></i>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <i class="fas fa-users-crown mr-2"></i>
                                <span>Supervisor</span>
                            </template>
                        </button>
                    </div><!-- /btn-group -->
                </template>
                <template v-else-if="pipe == 5">
                    <div class="w-100" role="group" :class="platform == 'mobile' ? 'btn-group-vertical' : 'btn-group'">
                        <button type="button" class="btn btn-secondary" @click="user.type = 1" :class="{'active': user.type == 1}" :disabled="user.type == 1">
                            <template v-if="platform == 'mobile'">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <span>Vendedor</span>
                                    </div>
                                    <div>
                                        <i class="fas fa-user-tag"></i>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <i class="fas fa-user-tag mr-2"></i>
                                <span>Vendedor</span>
                            </template>
                        </button>
                        <button type="button" class="btn btn-secondary" @click="user.type = 2" :class="{'active': user.type == 2}" :disabled="user.type == 2">
                            <template v-if="platform == 'mobile'">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <span>Gerente</span>
                                    </div>
                                    <div>
                                        <i class="fas fa-user-friends"></i>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <i class="fas fa-user-friends mr-2"></i>
                                <span>Gerente</span>
                            </template>
                        </button>
                    </div><!-- /btn-group -->
                </template>
               <div class="mt-3 mb-3">
                   <form @submit.prevent="newUser">
                        <div class="form-group">
                            <label for="name" class="text-light">Nome</label>
                            <input type="text" class="form-control" v-model="user.name" :readonly="loading == true" id="name" autocomplete="off" spellcheck="false">
                        </div>
                        <div class="form-group">
                            <label for="username" class="text-light">Usuário</label>
                            <input type="text" class="form-control" v-model="user.username" :readonly="loading == true" id="username" autocomplete="off" spellcheck="false">
                        </div>
                        <div class="form-group">
                            <label for="password" class="text-light">Senha</label>
                            <input type="password" class="form-control" v-model="user.password" :readonly="loading == true" id="password" autocomplete="off" spellcheck="false">
                        </div>
                        <div class="form-group">
                            <label for="phone" class="text-light">Telefone</label>
                            <input type="text" class="form-control" v-model="user.phone" :readonly="loading == true" id="phone" maxlength="14" autocomplete="off" spellcheck="false">
                        </div>
                        <div class="form-group">
                            <label for="address" class="text-light">Endereço</label>
                            <input type="text" class="form-control" v-model="user.address" :readonly="loading == true" id="address" autocomplete="off" spellcheck="false">
                        </div>
                        <div v-show="!loading && user.type == 1 && (pipe == 3 || pipe == 5)">
                            <div class="form-group">
                                <label for="manager_id" class="text-light">Gerente</label>
                                <div id="virtual-select" data-style="form-control"></div>
                                <!-- <select class="form-control" v-model="user.manager_id" :readonly="loading == true" id="manager_id">
                                    <option :value="0" :selected="user.manager_id == 0">Nenhum</option>
                                    <option :value="item.id" v-for="(item, index) in users" :key="index">{{item.nome}} - {{item.endereco}}</option>
                                </select> -->
                            </div>
                        </div>
                        <div v-show="!loading && user.type == 2 && pipe == 3">
                            <div class="form-group">
                                <label for="supervisor_id" class="text-light">Supervisor</label>
                                <div id="virtual-select-2" data-style="form-control"></div>
                                <!-- <select class="form-control" v-model="user.supervisor_id" :readonly="loading == true" id="supervisor_id">
                                    <option :value="0" :selected="user.supervisor_id == 0">Nenhum</option>
                                    <option :value="item.id" v-for="(item, index) in users" :key="index">{{item.nome}} - {{item.endereco}}</option>
                                </select> -->
                            </div>
                        </div>
                        <div class="form-group" v-show="user.type == 1">
                            <label for="minimum_bet_amount" class="text-light">Valor mínimo da aposta</label>
                            <input type="text" class="form-control" v-model="user.minimum_bet_amount" :readonly="loading == true" id="minimum_bet_amount" autocomplete="off" spellcheck="false">
                        </div>
                        <div class="form-group" v-show="user.type == 1">
                            <label for="maximum_bet_amount" class="text-light">Valor máximo da aposta</label>
                            <input type="text" class="form-control" v-model="user.maximum_bet_amount" :readonly="loading == true" id="maximum_bet_amount" autocomplete="off" spellcheck="false">
                        </div>
                        <div class="form-group" v-show="user.type == 1">
                            <label for="minimum_bet_games" class="text-light">Mínimo de jogos por aposta</label>
                            <input type="text" class="form-control" v-model="user.minimum_bet_games" :readonly="loading == true" id="minimum_bet_games" autocomplete="off" spellcheck="false">
                        </div>
                        <div class="form-group" v-show="user.type == 1 || user.type == 2 || user.type == 5">
                            <label for="cancel_bet" class="text-light">Cancelar aposta</label>
                            <select class="form-control" v-model="user.cancel_bet" :readonly="loading == true" id="cancel_bet">
                                <option value="1">Sim</option>
                                <option value="2" selected>Não</option>
                            </select>
                        </div>
                        <div class="form-group" v-show="user.type == 1">
                            <label for="livebet" class="text-light">Realizar aposta em jogos ao vivo</label>
                            <select class="form-control" v-model="user.livebet" :readonly="loading == true" id="livebet">
                                <option value="1">Sim</option>
                                <option value="2" selected>Não</option>
                            </select>
                        </div>
                        <div class="form-group" v-show="user.type == 1 || user.type == 2 || user.type == 5">
                            <label for="credit" class="text-light">
                                <span v-if="user.type == 1">Crédito para realizar apostas</span>
                                <span v-else>Crédito inicial</span>
                            </label>
                            <input type="text" class="form-control" v-model="user.credit" :readonly="loading == true" id="credit" autocomplete="off" spellcheck="false">
                        </div>
                        <div class="form-group" v-show="user.type == 1">
                            <label for="winning_bet_commission" class="text-light">Comissão das apostas premiadas</label>
                            <template v-if="change_commissions">
                                <input type="text" class="form-control" v-model="user.winning_bet_commission" :readonly="loading == true || change_commissions == false" id="winning_bet_commission" autocomplete="off" spellcheck="false">
                            </template>
                            <template v-else>
                                <input type="text" class="form-control" :value="user.winning_bet_commission" :readonly="loading == true || change_commissions == false" id="winning_bet_commission" autocomplete="off" spellcheck="false" @click="showAlert()">
                            </template>
                        </div>
                        <div class="form-group" v-show="user.type == 1">
                            <label for="close_bet" class="text-light">Permitir o encerramento de apostas</label>
                            <select class="form-control" v-model="user.permissions.encerrar_aposta" :readonly="loading == true" id="close_bet">
                                <option value="sim">Sim</option>
                                <option value="nao" selected>Não</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <div v-if="user.type == 1">
                                <div class="btn-group w-100 mb-2">
                                    <button type="button" class="btn btn-secondary" @click="cat = 1" :class="{'active': cat == 1}" :disabled="cat == 1">
                                        <i class="fas fa-asterisk mr-2" v-show="platform == 'desktop'"></i>
                                        <span>Pré-jogo</span>
                                    </button>
                                    <button type="button" class="btn btn-secondary" @click="cat = 2" :class="{'active': cat == 2}" :disabled="cat == 2">
                                        <i class="fas fa-tv mr-2" v-show="platform == 'desktop'"></i>
                                        <span>Ao vivo</span>
                                    </button>
                                    <button type="button" class="btn btn-secondary" @click="cat = 3" :class="{'active': cat == 3}" :disabled="cat == 3" v-if="pipe == 3">
                                        <i class="fas fa-wand-magic mr-2" v-show="platform == 'desktop'"></i>
                                        <span>Personalizado</span>
                                    </button>
                                </div><!-- /btn-group -->
                                <template v-if="cat == 1">
                                    <div class="form-group mb-3" v-for="(item, index) in user.percents.pre_jogo" :key="index">
                                        <label class="text-light" :for="`pregame-${index}`">Comissão {{index}} {{index == 1 ? 'jogo' : index == 20 ? ' jogos ou mais...' : 'jogos'}}</label>
                                        <template v-if="change_commissions">
                                            <input type="text" :id="`pregame-${index}`" v-model="user.percents.pre_jogo[index]" :readonly="loading == true" @keypress="IsNumber($event, user.percents.pre_jogo[index])" class="form-control">
                                        </template>
                                        <template v-else>
                                            <input type="text" :id="`pregame-${index}`" :value="user.percents.pre_jogo[index]" :readonly="loading == true || change_commissions == false" @click="showAlert()" class="form-control">
                                        </template>
                                    </div><!-- /form-group -->   
                                </template>
                                <template v-else-if="cat == 2">
                                    <div class="form-group mb-3" v-for="(item, index) in user.percents.ao_vivo" :key="index">
                                        <label class="text-light" :for="`livegame-${index}`">Comissão {{index}} {{index == 1 ? 'jogo' : index == 20 ? ' jogos ou mais...' : 'jogos'}}</label>
                                        <template v-if="change_commissions">
                                            <input type="text" :id="`livegame-${index}`" v-model="user.percents.ao_vivo[index]" :readonly="loading == true || change_commissions == false" @keypress="IsNumber($event, user.percents.ao_vivo[index])" class="form-control">
                                        </template>
                                        <template v-else>
                                            <input type="text" :id="`livegame-${index}`" :value="user.percents.ao_vivo[index]" :readonly="loading == true || change_commissions == false" @click="showAlert()" class="form-control">
                                        </template>
                                    </div><!-- /form-group -->  
                                </template>
                                <template v-else>
                                    <template v-if="comissoes_padrao.length == 0">
                                        <div class="alert alert-warning text-center">Nenhum padrão de comissão disponível</div>
                                    </template>
                                    <template v-else>
                                        <div class="card mt-2" v-for="(item, index) in comissoes_padrao" :key="index" :class="{'bg-success text-white': comissoes_padrao_id == item._id}">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col text-truncate">{{ item.nome }}</div>
                                                    <div class="col-auto">
                                                        <a href="javascript:;" @click="selectCommission(item._id)" class="btn btn-block btn-sm" :class="comissoes_padrao_id == item._id ? 'btn-danger' : 'btn-success'">
                                                            <template v-if="comissoes_padrao_id == item._id">
                                                                <i class="fas fa-minus-circle"></i>
                                                            </template>
                                                            <template v-else>
                                                                <i class="fas fa-check-circle"></i>
                                                            </template>
                                                        </a>
                                                    </div>
                                                    <div class="col-auto">
                                                        <a href="javascript:;" @click="viewCommission(item)" class="btn btn-block btn-sm btn-secondary">
                                                            <i class="fas fa-eye"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </div><!-- /div -->
                            <div v-else-if="user.type == 2">
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label class="text-light">Comissão</label>
                                        <input type="text" v-model="user.percents.pre_jogo[1]" :readonly="loading == true" class="form-control">
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="edit-user" class="text-light">Editar vendedor</label>
                                        <select class="form-control" v-model="user.permissions.editar_vendedor" :readonly="loading == true" id="edit-user">
                                            <option value="sim">Sim</option>
                                            <option value="nao" selected>Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="register-user" class="text-light">Cadastrar vendedor</label>
                                        <select class="form-control" v-model="user.permissions.cadastrar_vendedor" :readonly="loading == true" id="register-user">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3" v-if="pipe == 3">
                                    <div class="col-12">
                                        <label for="commission-user" class="text-light">Comissão do apurado</label>
                                        <select class="form-control" v-model="user.permissions.comissao_do_apurado" :readonly="loading == true" id="commission-user">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="credit-user" class="text-light">Liberar crédito para o vendedor</label>
                                        <select class="form-control" v-model="user.permissions.liberar_credito" :readonly="loading == true" id="credit-user">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3" v-if="pipe == 3">
                                    <div class="col-12">
                                        <label for="comissions-user" class="text-light">Alterar comissões do vendedor</label>
                                        <select class="form-control" v-model="user.permissions.alterar_comissoes" :readonly="loading == true" id="comissions-user">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                            </div>
                            <div v-else-if="user.type == 5">
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label class="text-light">Comissão</label>
                                        <input type="text" v-model="user.percents.pre_jogo[1]" :readonly="loading == true" class="form-control">
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="edit-user" class="text-light">Fazer lançamentos</label>
                                        <select class="form-control" v-model="user.permissions.fazer_lancamento" :readonly="loading == true" id="edit-user">
                                            <option value="sim">Sim</option>
                                            <option value="nao" selected>Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="edit-user" class="text-light">Editar gerente e vendedor</label>
                                        <select class="form-control" v-model="user.permissions.editar_vendedor" :readonly="loading == true" id="edit-user">
                                            <option value="sim">Sim</option>
                                            <option value="nao" selected>Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="register-user" class="text-light">Cadastrar gerente e vendedor</label>
                                        <select class="form-control" v-model="user.permissions.cadastrar_usuarios" :readonly="loading == true" id="register-user">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="credit-user" class="text-light">Liberar crédito para gerente e vendedor</label>
                                        <select class="form-control" v-model="user.permissions.liberar_credito" :readonly="loading == true" id="credit-user">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="comissions-user" class="text-light">Alterar comissões do gerente e vendedor</label>
                                        <select class="form-control" v-model="user.permissions.alterar_comissoes" :readonly="loading == true" id="comissions-user">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="block-user" class="text-light">Bloquear/Desbloquear gerente e vendedor</label>
                                        <select class="form-control" v-model="user.permissions.bloquear_desbloquer_usuarios" :readonly="loading == true" id="block-user">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                            </div>
                            <div v-else-if="user.type == 3">
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="view-bet" class="text-light">Ver apostas</label>
                                        <select class="form-control" v-model="user.permissions.ver_aposta" :readonly="loading == true" id="view-bet">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="delete-bet" class="text-light">Excluir apostas</label>
                                        <select class="form-control" v-model="user.permissions.excluir_aposta" :readonly="loading == true" id="delete-bet">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="view-events" class="text-light">Ver eventos</label>
                                        <select class="form-control" v-model="user.permissions.ver_eventos" :readonly="loading == true" id="view-events">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="edit-events" class="text-light">Editar eventos</label>
                                        <select class="form-control" v-model="user.permissions.editar_eventos" :readonly="loading == true" id="edit-events">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="edit-leagues" class="text-light">Editar ligas</label>
                                        <select class="form-control" v-model="user.permissions.editar_ligas" :readonly="loading == true" id="edit-leagues">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="edit-odds" class="text-light">Editar cotações</label>
                                        <select class="form-control" v-model="user.permissions.editar_cotacoes" :readonly="loading == true" id="edit-odds">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="edit-configs" class="text-light">Editar configurações</label>
                                        <select class="form-control" v-model="user.permissions.editar_configuracoes" :readonly="loading == true" id="edit-configs">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="edit-rules-leagues" class="text-light">Editar regras por liga</label>
                                        <select class="form-control" v-model="user.permissions.editar_regras_por_liga" :readonly="loading == true" id="edit-rules-leagues">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="edit-rules-clients" class="text-light">Editar regras por clientes</label>
                                        <select class="form-control" v-model="user.permissions.editar_regras_por_clientes" :readonly="loading == true" id="edit-rules-clients">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="edit-rules-managers" class="text-light">Editar regras por gerentes</label>
                                        <select class="form-control" v-model="user.permissions.editar_regras_por_gerentes" :readonly="loading == true" id="edit-rules-managers">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="edit-rules-salesman" class="text-light">Editar regras por vendedores</label>
                                        <select class="form-control" v-model="user.permissions.editar_regras_por_vendedores" :readonly="loading == true" id="edit-rules-salesman">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="view-cash-general" class="text-light">Ver caixa geral</label>
                                        <select class="form-control" v-model="user.permissions.caixa_geral" :readonly="loading == true" id="view-cash-general">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="view-cash-manager" class="text-light">Ver caixa gerente</label>
                                        <select class="form-control" v-model="user.permissions.caixa_gerente" :readonly="loading == true" id="view-cash-manager">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="view-cash-supervisor" class="text-light">Ver caixa supervisor</label>
                                        <select class="form-control" v-model="user.permissions.caixa_supervisor" :readonly="loading == true" id="view-cash-supervisor">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="view-cash-client" class="text-light">Ver caixa cliente</label>
                                        <select class="form-control" v-model="user.permissions.caixa_cliente" :readonly="loading == true" id="view-cash-client">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                                <div class="form-row mb-3">
                                    <div class="col-12">
                                        <label for="view-cash-salesman" class="text-light">Ver caixa vendedores</label>
                                        <select class="form-control" v-model="user.permissions.caixa_vendedor" :readonly="loading == true" id="view-cash-salesman">
                                            <option value="sim" selected>Sim</option>
                                            <option value="nao">Não</option>
                                        </select>
                                    </div>
                                </div><!-- /form-row -->
                            </div>
                        </div><!-- /mb-3 -->
                        <button type="submit" class="btn btn-primary mb-3" :class="{'btn-block': platform == 'mobile', 'float-right': platform == 'desktop'}" :disabled="loading == true">
                            <template v-if="loading == false">
                                <i class="fas fa-check-circle mr-2"></i>
                                <span>Criar conta</span>
                            </template>
                            <template v-else>
                                <i class="fas fa-spinner fa-spin mr-2"></i>
                                <span>Criando conta...</span>
                            </template> 
                        </button>
                    </form>
               </div><!-- /mt-3 mb-3 -->
           </div><!-- /container -->
        </div><!-- /main -->
      <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'virtual-select-plugin/dist/virtual-select.min.css'
import 'virtual-select-plugin/dist/virtual-select.min.js'

export default {
    data() {
        return {
            user: {
                type: 1,
                name: '',
                phone: '',
                address: '',
                username: '',
                password: '',
                livebet: 2,
                credit: 1000,
                manager_id: 0,
                supervisor_id: 0,
                cancel_bet: 2,
                cancel_bet_time: 5,
                minimum_bet_games: 1,
                minimum_bet_amount: 2,
                maximum_bet_amount: 100,
                cumulative_commission: 0,
                winning_bet_commission: 0,
                permissions: {
                    prestar_contas: 'nao',
                    editar_vendedor: 'nao',
                    cadastrar_vendedor: 'sim', 
                    liberar_credito: 'sim', 
                    alterar_comissoes: 'nao', 
                    credito_simples: 'nao',
                    comissao_do_apurado: 'nao',
                    encerrar_aposta: 'nao',
                    editar_usuarios: 'nao',
                    cadastrar_usuarios: 'sim',
                    bloquear_desbloquer_usuarios: 'sim',
                    ver_aposta: 'sim',
                    excluir_aposta: 'sim',
                    ver_eventos: 'sim',
                    editar_eventos: 'sim',
                    editar_ligas: 'sim',
                    editar_cotacoes: 'sim',
                    editar_configuracoes: 'sim',
                    editar_regras_por_liga: 'sim',
                    editar_regras_por_clientes: 'sim',
                    editar_regras_por_gerentes: 'sim',
                    editar_regras_por_vendedores: 'sim',
                    caixa_geral: 'sim',
                    caixa_gerente: 'sim',
                    caixa_supervisor: 'sim',
                    caixa_cliente: 'sim',
                    caixa_vendedor: 'sim',
                    fazer_lancamento: 'nao'
                },
                percents: {
                    pre_jogo: {
                        1: 5,
                        2: 7,
                        3: 10,
                        4: 11,
                        5: 12,
                        6: 13,
                        7: 14,
                        8: 15,
                        9: 15,
                        10: 15,
                        11: 15,
                        12: 15,
                        13: 15,
                        14: 15,
                        15: 15,
                        16: 15,
                        17: 15,
                        18: 15,
                        19: 15,
                        20: 15
                    },
                    ao_vivo: {
                        1: 5,
                        2: 7,
                        3: 10,
                        4: 11,
                        5: 12,
                        6: 13,
                        7: 14,
                        8: 15,
                        9: 15,
                        10: 15,
                        11: 15,
                        12: 15,
                        13: 15,
                        14: 15,
                        15: 15,
                        16: 15,
                        17: 15,
                        18: 15,
                        19: 15,
                        20: 15
                    }
                }
            },
            cat: 1,
            pipe: 0,
            users: [],
            loading: false,
            percentsGeral: {
                pre_jogo: {
                    1: 5,
                    2: 7,
                    3: 10,
                    4: 11,
                    5: 12,
                    6: 13,
                    7: 14,
                    8: 15,
                    9: 15,
                    10: 15,
                    11: 15,
                    12: 15,
                    13: 15,
                    14: 15,
                    15: 15,
                    16: 15,
                    17: 15,
                    18: 15,
                    19: 15,
                    20: 15
                },
                ao_vivo: {
                    1: 5,
                    2: 7,
                    3: 10,
                    4: 11,
                    5: 12,
                    6: 13,
                    7: 14,
                    8: 15,
                    9: 15,
                    10: 15,
                    11: 15,
                    12: 15,
                    13: 15,
                    14: 15,
                    15: 15,
                    16: 15,
                    17: 15,
                    18: 15,
                    19: 15,
                    20: 15
                }
            },
            comissoes_padrao: [],
            comissoes_padrao_db: {},
            comissoes_padrao_id: '',
            change_commissions: false,
            gerente_excluir_aposta: 2,
            vendedor_excluir_aposta: 2,
            isValSports: window.location.hostname.includes('valsports'),
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        newUser() {
            
            const self = this;

            if (self.user.name.trim() == '') {
                Swal.fire(
                    'Atenção',
                    'Informe o nome!',
                    'info'
                );
            } else if (self.user.username.trim() == '') {
                Swal.fire(
                    'Atenção',
                    'Informe o usuário!',
                    'info'
                );
            } else if (self.user.username.trim().length < 4) {
                Swal.fire(
                    'Atenção',
                    'O usuário tem que ter no mínimo 4 caracteres!',
                    'info'
                );
            } else if (self.user.password.trim() == '') {
                Swal.fire(
                    'Atenção',
                    'Informe a senha!',
                    'info'
                );
            } else if (self.user.password.trim().length < 4) {
                Swal.fire(
                    'Atenção',
                    'A senha tem que ter no mínimo 4 caracteres!',
                    'info'
                );
            } else if (self.pipe == 5 && self.type == 1 && self.user.manager_id == 0) {
                Swal.fire(
                    'Atenção',
                    'É obrigatório selecionar um gerente para poder cadastrar um vendedor!',
                    'info'
                );
            } else if (self.user.phone.trim() == '') {
                Swal.fire(
                    'Atenção',
                    'Informe o número do telefone!',
                    'info'
                );
            } else if (self.user.phone.trim().length < 14) {
                Swal.fire(
                    'Atenção',
                    'O número de telefone é inválido!',
                    'info'
                );
            } else if (self.user.address.trim() == '') {
                Swal.fire(
                    'Atenção',
                    'Informe o endereço!',
                    'info'
                );
            } else if (self.user.minimum_bet_amount == '') {
                Swal.fire(
                    'Atenção',
                    'Informe o valor mínimo da aposta!',
                    'info'
                );
            } else if (self.user.maximum_bet_amount == '') {
                Swal.fire(
                    'Atenção',
                    'Informe o valor máximo da aposta!',
                    'info'
                );
            } else if (self.user.minimum_bet_games == '') {
                Swal.fire(
                    'Atenção',
                    'Informe o valor mínimo de jogos por aposta!',
                    'info'
                );
            } else if (self.user.credit == '') {
                Swal.fire(
                    'Atenção',
                    'Informe o crédito!',
                    'info'
                );
            } else if (self.user.cumulative_commission.toString() == '') {
                Swal.fire(
                    'Atenção',
                    'Informe a comissão do acumuladão!',
                    'info'
                );
            } else if (self.user.winning_bet_commission.toString() == '') {
                Swal.fire(
                    'Atenção',
                    'Informe a comissão das apostas premiadas!',
                    'info'
                );
            } else if (self.checkIsValue()) {
                Swal.fire(
                    'Atenção',
                    'Informe todas as comissões!',
                    'info'
                );
            } 
            else 
            {
                self.loading = true;

                api.post('dashboard/user', {
                    data: this.user
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            self.user.name = '';
                            self.user.phone = '';
                            self.user.address = '';
                            self.user.username = '';
                            self.user.password = '';
                            Swal.fire(
                                'Sucesso',
                                'Usuário cadastrado com sucesso!',
                                'success'
                            );
                        break;
                        default:
                            Swal.fire(
                                'Falha',
                                response.data.message,
                                'error'
                            );
                        break;
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.loading = false;
                });
            }
        },
        removeAccents(str) {

            let string = str;
            let map = {
                a : /[\xE0-\xE6]/g,
                A : /[\xC0-\xC6]/g,
                e : /[\xE8-\xEB]/g,
                E : /[\xC8-\xCB]/g,
                i : /[\xEC-\xEF]/g,
                I : /[\xCC-\xCF]/g,
                o : /[\xF2-\xF6]/g,
                O : /[\xD2-\xD6]/g,
                u : /[\xF9-\xFC]/g,
                U : /[\xD9-\xDC]/g,
                c : /\xE7/g,
                C : /\xC7/g,
                n : /\xF1/g,
                N : /\xD1/g,
            };

            for (let key in map) {
                let rex = map[key];
                string = string.replace(rex, key);
            }

            return string;
        },
        checkIsValue() {

            let stop = false;

            for (let key in this.user.percents) {

               for (let index in this.user.percents[key]) {
                   let value = this.user.percents[key][index];
                
                   if (value.toString().length == 0) {
                       stop = true;
                       break;
                   } else {
                       this.user.percents[key][index] = parseInt(value);
                   }
               }
            }

            return stop;
        },
        IsNumber(event) {
            if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault();
        },
        showAlert() {

            if (!this.change_commissions) {
                Swal.fire(
                    'Atenção!',
                    'Você não tem permissão para alterar as comissões. Caso tenha alguma dúvida, entre em contato com o administrador!',
                    'warning'
                );
            }
        },
        getUsers(type) {

            const self = this;

            self.users = [];
            self.loading = true;

            api.get(`dashboard/users/${type}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.users = response.data.users.sort((a, b) => {
                            return self.removeAccents(a.nome).localeCompare(self.removeAccents(b.nome));
                        });
                    break;
                    case 'nothing_found':
                        self.user.manager_id = 0;
                    break;
                    default:
                        Swal.fire(
                            'Atenção!',
                            'Ocorreu um erro, tente novamente!',
                            'warning'
                        );
                    break;
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha!',
                        self.network_erros[error.status]['message'],
                        'warning'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha!',
                        self.network_erros[408]['message'],
                        'warning'
                    );
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        saveCommissions(percents) {
            try {
                window.sessionStorage.setItem('percent', percents['pre_jogo']['1']);
            } catch(e) {
                window.sessionStorage.setItem('percent', 5);
            }
        },
        getCommissions() {
            try {
                return (window.sessionStorage.getItem('percent') || 5)
            } catch(e) {
                return 5;
            }
        },
        selectCommission(id) {
            if (this.comissoes_padrao_id == id) {
                this.comissoes_padrao_id = '';
            } else {
                this.comissoes_padrao_id = id;
            }
        },
        viewCommission(item) {

            let i = 1;
            let j = 1;
            let str1 = '';
            let str2 = '';

            for (let key in item.comissoes.pre_jogo) {
                let commission = item.comissoes.pre_jogo[key];
                let label = i == 1 ? 'Comissão 1 jogo' : i == 20 ? 'Comissão 20 ou mais...' : `Comissão ${i} jogos`;
                
                str1 += `
                    <div class="row">
                        <div class="col text-left">${label}</div>
                        <div class="col-auto">${commission}%</div>
                    </div>
                `;

                if (i < 20) {
                    str1 += '<hr/>';
                }

                i++;
            }

            for (let key in item.comissoes.ao_vivo) {
                let commission = item.comissoes.ao_vivo[key];
                let label = j == 1 ? 'Comissão 1 jogo' : j == 20 ? 'Comissão 20 ou mais...' : `Comissão ${j} jogos`;
                
                str2 += `
                    <div class="row">
                        <div class="col text-left">${label}</div>
                        <div class="col-auto">${commission}%</div>
                    </div>
                `;

                if (j < 20) {
                    str2 += '<hr/>';
                }
                
                j++;
            }

            Swal.fire(
                'Comissões',
                `<p class="mb-3">Você está vendo as comissões do padrão<br><b>"${item.nome}"</b>.</p>
                <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="pregame-tab" data-toggle="tab" href="#pregame" role="tab" aria-controls="pregame" aria-selected="true">Pré-jogo</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="inplay-tab" data-toggle="tab" href="#inplay" role="tab" aria-controls="inplay" aria-selected="false">Ao vivo</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="pregame" role="tabpanel" aria-labelledby="pregame-tab">
                        ${str1}
                    </div>
                    <div class="tab-pane fade" id="inplay" role="tabpanel" aria-labelledby="inplay-tab">
                        ${str2}
                    </div>
                </div>
                `
            )
        }
    },
    computed: {
        type() {
            return this.user.type;
        },
        name() {
            return this.user.name;
        },
        phone() {
            return this.user.phone;
        },
        username() {
            return this.user.username;
        },
        password() {
            return this.user.password;
        },
        cancelBetTime() {
            return this.user.cancel_bet_time;
        },
        minimumBetAmount() {
            return this.user.minimum_bet_amount;
        },
        maximumBetAmount() {
            return this.user.maximum_bet_amount;
        },
        minimumBetGames() {
            return this.user.minimum_bet_games;
        },
        credit() {
            return this.user.credit;
        },
        cumulativeCommission() {
            return this.user.cumulative_commission;
        },
        winningBetCommission() {
            return this.user.winning_bet_commission;
        }
    },
    watch: {
        type(value) {
            if (value == 1) {
                this.getUsers(2);
                this.user.cancel_bet = this.vendedor_excluir_aposta;
                this.user.percents.pre_jogo[1] = this.getCommissions();
            } else if (value == 2) {
                this.getUsers(5);
                this.user.percents.pre_jogo[1] = 5;
                this.user.cancel_bet = this.gerente_excluir_aposta;
            } else if (value == 5 && this.isValSports) {
                this.user.credit = 0;
                this.user.cancel_bet = 2;
                this.user.percents.pre_jogo[1] = 0;
            }
        },
        users(users) {

            var select = document.querySelector(this.user.type == 1 ? '#virtual-select' : '#virtual-select-2');
            
            if (select && users.length) {

                const options = [{
                    value: '0',
                    label: 'Nenhum'
                }];

                for (const item of users) {
                    options.push({
                        value: item.id,
                        label: item.nome,
                        description: item.endereco
                    });
                }

                select.setOptions(options);
            }
        },
        cat(value) {
            if (value == 3) {

                var select = document.querySelector('#virtual-select-3');
            
                if (select && this.comissoes_padrao.length) {

                    const options = [{
                        value: '0',
                        label: 'Nenhum'
                    }];

                    for (const item of this.comissoes_padrao) {
                        options.push({
                            value: item._id,
                            label: item.nome
                        });
                    }

                    select.setOptions(options);
                }
            }
        },
        name(value) {
            return this.user.username = (value.trim().length > 0 ? [this.removeAccents(value).toLowerCase().replace(/[^a-z]/g, ''), (Math.floor(new Date().valueOf() * Math.random())).toString().substr(0,3)].join('.') : '');
        },
        phone(value) {
            return this.user.phone = value.replace(/[^0-9]/g, '').replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        },
        username(value) {
            return this.user.username = value.replace(/[^a-z0-9.]/g, '');
        },
        password(value) {
            return this.user.password = value.replace(/[^a-zA-Z0-9]/g, '');
        },
        cancelBetTime(value) {
            return this.user.cancel_bet_time = value.replace(/[^0-9]/g, '');
        },
        minimumBetAmount(value) {
            return this.user.minimum_bet_amount = value.replace(/[^0-9]/g, '');
        },
        maximumBetAmount(value) {
            return this.user.maximum_bet_amount = value.replace(/[^0-9]/g, '');
        },
        minimumBetGames(value) {
            return this.user.minimum_bet_games = value.replace(/[^0-9]/g, '');
        },
        credit(value) {
            return this.user.credit = value.toString().replace(/[^0-9]/g, '');
        },
        cumulativeCommission(value) {
            return this.user.cumulative_commission = value.replace(/[^0-9]/g, '');
        },
        winningBetCommission(value) {
            return this.user.winning_bet_commission = value.replace(/[^0-9]/g, '');
        },
        comissoes_padrao(values) {
            const comissoes = {};
            if (values.length) {
                for (const item of values) {
                    comissoes[item._id] = item.comissoes;
                }
            }
            this.comissoes_padrao_db = comissoes;
        },
        comissoes_padrao_id(value) {
            if (value == '') {
                this.user.percents = this.percentsGeral;
            } else {
                this.user.percents = this.comissoes_padrao_db[value];
            }
        }
    },
    beforeMount() {
        try {
            const {pipe} = JSON.parse(window.localStorage.getItem('auth'));
            this.pipe = pipe;
        } catch(e) {
            this.pipe = 0;
        }
    },
    mounted() {

        const self = this;

        window.VirtualSelect.init({
            ele: '#virtual-select',
            search: true,
            placeholder: 'Selecione um gerente',
            noOptionsText: 'Nenhuma opção disponível',
            noSearchResultsText: 'Nenhum resultado encontrado',
            searchPlaceholderText: 'Buscar gerente...',
            hasOptionDescription: true,
            selectedValue: '0',
        });

        window.VirtualSelect.init({
            ele: '#virtual-select-2',
            search: true,
            placeholder: 'Selecione um supervisor',
            noOptionsText: 'Nenhuma opção disponível',
            noSearchResultsText: 'Nenhum resultado encontrado',
            searchPlaceholderText: 'Buscar supervisor...',
            hasOptionDescription: true,
            selectedValue: '0',
        });

        if (self.isValSports) {
            self.user.permissions.ver_aposta = 'nao';
            self.user.permissions.ver_eventos = 'nao';
            self.user.permissions.caixa_geral = 'nao';
            self.user.permissions.editar_ligas = 'nao';
            self.user.permissions.caixa_gerente = 'nao';
            self.user.permissions.caixa_cliente = 'nao';
            self.user.permissions.caixa_vendedor = 'nao';
            self.user.permissions.editar_eventos = 'nao';
            self.user.permissions.excluir_aposta = 'nao';
            self.user.permissions.liberar_credito = 'nao'; 
            self.user.permissions.editar_cotacoes = 'nao';
            self.user.permissions.caixa_supervisor = 'nao';
            self.user.permissions.cadastrar_usuarios = 'nao';
            self.user.permissions.cadastrar_vendedor = 'nao'; 
            self.user.permissions.editar_configuracoes = 'nao';
            self.user.permissions.editar_regras_por_liga = 'nao';
            self.user.permissions.editar_regras_por_clientes = 'nao';
            self.user.permissions.editar_regras_por_gerentes = 'nao';
            self.user.permissions.editar_regras_por_vendedores = 'nao';
            self.user.permissions.bloquear_desbloquer_usuarios = 'nao';
        }
        
        if (document.querySelector('#virtual-select')) {
            document.querySelector('#virtual-select').addEventListener('change', function() {
                self.user.manager_id = this.value ? this.value : 0;
            });
        }

        if (document.querySelector('#virtual-select-2')) {
            document.querySelector('#virtual-select-2').addEventListener('change', function() {
                self.user.supervisor_id = this.value ? this.value : 0;
            });
        }
    },
    created() {

        const self = this;

        self.loading = true;

        api.get('dashboard/commission-standard').then((response) => {
            switch(response.data.result) {
                case 'success':
                    self.comissoes_padrao = response.data.comissoes_padrao;
                    self.percentsGeral = response.data.comissoes;
                    self.change_commissions = response.data.change_commissions;
                    self.user.percents = response.data.comissoes;
                    self.user.livebet = response.data.configs.ao_vivo;
                    self.user.minimum_bet_games = response.data.configs.min_jogos;
                    self.user.minimum_bet_amount = response.data.configs.min_valor;
                    self.user.winning_bet_commission = response.data.comissao_premio;
                    self.user.maximum_bet_amount = response.data.configs.max_valor_aposta;
                    self.user.cancel_bet = response.data.configs.vendedor_excluir_aposta;
                    self.user.permissions.encerrar_aposta = response.data.configs.encerrar_aposta;
                    self.gerente_excluir_aposta = response.data.configs.gerente_excluir_aposta;
                    self.vendedor_excluir_aposta = response.data.configs.vendedor_excluir_aposta;
                    self.saveCommissions(response.data.comissoes);
                    self.getUsers(2);
                break;
                default:
                    self.error = response.data.message;
                break;
            }
        }).catch((error) => {
            try {
                Swal.fire(
                    'Falha!',
                    self.network_erros[error.status]['message'],
                    'warning'
                );
            } catch(e) {
                Swal.fire(
                    'Falha!',
                    self.network_erros[408]['message'],
                    'warning'
                );
            }
        }).finally(() => {
            self.loading = false;
        });

        window.addEventListener('resize', () => {
            self.platform = self.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
</style>